// Overlay
function openOverlay(olEl) {
	$oLay = $(olEl);
	
	if ($('#overlay-shade').length == 0)
		$('body').prepend('<div id="overlay-shade"></div>');
		$('.overlay').prepend('<a class="overlay-close"></a>');

	$('#overlay-shade').fadeTo(300, 0.6, function() {
		var props = {
			oLayWidth       : $oLay.width(),
			scrTop          : $(window).scrollTop(),
			viewPortWidth   : $(window).width()
		};

		var leftPos = (props.viewPortWidth - props.oLayWidth) / 2;

		$oLay
			.css({
				display : 'block',
				opacity : 0,
				left : leftPos+'px'
			})
			.animate({
				// 高度
				top : props.scrTop + 50,
				opacity : 1
			}, 600);
			
			
	});
}

function closeOverlay() {
	$('.overlay').animate({
		top : '-=300',
		opacity : 0
	}, 400, function() {
		$('#overlay-shade').fadeOut(300);
		$(this).css('display','none');

		
	});
}
 

// slider effect
// all slider page
$(window).load(function() {

	$('#slider').nivoSlider({

    animSpeed: 1000,
    pauseTime: 8000,
    controlNav:false,

		afterLoad: function (){

            //get wrap height
            var getWinWidth = $(window).width();
            if ( getWinWidth > 1200 ){
                $('.index-slider').css({"min-height":(getWinWidth*0.325)+"px"});
            }else{
                $('.index-slider').css({"min-height":"100%"});
            };

             //main img fadein
            $('.nivoSlider').animate({opacity: 1}).fadeIn(1200);//初始圖ˊfadeIn
            // $('.nivoSlider').hide().fadeIn(1200); 
			}, 
		
		beforeChange: function () {
			setTimeout(function () {
				$('.nivo-caption').fadeOut(700, function() { //title消失速度
					setTimeout(function () {
						$('.nivo-caption').fadeIn(1500); //出現速度
					},900); //切換速度
				});
			},400); //切換速度
		}
	});
});/*[]*/











$(function(){
	
	// left
	$('.nivo-caption,.map').css({
	    left : ($(window).width() - $('.width').width()) / 2
	});

	// menu
	var $oe_menu		= $('#oe_menu');
	var $oe_menu_items	= $oe_menu.children('li');

	$oe_menu_items.bind('mouseenter',function(){

		var $this = $(this);
		$this.addClass('slided');
		//$this.children('div').parent().addClass('arrow');
		$this.children('div').css('z-index','9999').stop(true,true).delay(200).slideDown(function(){
			//$oe_menu_items.not('.slided').children('div').hide();
			$this.removeClass('slided');

		});

	}).bind('mouseleave',function(){
			var $this = $oe_menu.children('li');

			//選單arrow
			//$this.removeClass('arrow');

			//mouseleave 選單消失
			if  ($(window).scrollTop() < 280) {
				$this.children('div').stop(true,true).slideUp('slow');
			}else{
				//alert(">280");
			};
	});

 

	
	// language slideToggle
	$(".language").hover(function(){
		$(".language dd").stop().slideToggle();
	});

	
	
	// overlay
    $('#overlay-shade, .overlay-close').live('click', function(e) {
        closeOverlay();
        if ($(this).attr('href') == '#') e.preventDefault();
    });

    $('#overlay-link').click(function(e) {
       openOverlay('#overlay-box');
       e.preventDefault();
    });




	// scroll top
	$('#top').hide().click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 800);
		return false;
	});
	
	$(window).scroll(function () {
		if ($(this).scrollTop() > 0) {

			$('#top').fadeIn();
		} else {
			$('#top').fadeOut();
		}

	});

	 



 
 
 	//top full width slider  banner 
    //(when resize) get wrap height 
    $( window ).resize(function() {
        var getWinWidth = $(window).width();
        if ( getWinWidth > 1200 ){
            $('.index-slider').css({"min-height":(getWinWidth*0.325)+"px"});
        }else{
            $('.index-slider').css({"min-height":"100%"});
        };
    }); /**/




	//mobile menu  不能copy oe_menu除非第一層非連結
	$('body').append('<div id="mobile-btn" class="word"></div><div class="accordion mobile-menu" id="mobile"></div>');
		
	$(".sitemap").children("dl").clone()
	.appendTo('#mobile').removeClass('dl');

	//topnav 重複
	// $("#topnav").find("a").clone()
	// .appendTo('#mobile').removeClass('blue-btn').wrap("<dl></dl>").wrap("<dt></dt>");

	//m-menu第一層為link時 / 無子項目 不出現箭頭
	if ($('#mobile-btn dt').has('a')){
 		//$(this).find('a').parent().css('background-image','none');
 		$(this).find('a').parent().addClass('no-child');
	};

	$('#mobile-btn').click( function(){
		$('.mobile-bg').hide();
		var switchBtn = $('.mobile-menu'); 

		if(switchBtn.hasClass('btn-open')){
			//close
			$('.mobile-menu').animate({ right: -250 }, 300).removeClass('btn-open');
			$('#mobile-btn').removeClass('on'); //menu word
		}else{
			//open
			$('.mobile-menu').addClass('on').animate({ right: 0 }, 300).addClass('btn-open');
			$('#mobile-btn').addClass('on');//menu word
			$('.mobile-bg').fadeIn()
		}
	});

	 
	//support Q&A + mobiile menu
	// accordion slideToggle
	$(".accordion dd").hide();	
	$(".accordion .active").next("dd").show();
	$(".accordion dt").click(
		function(){
			$(this).next("dd").slideToggle("500")
			.siblings("dd:visible").slideUp("500");
			$(this).toggleClass("active");
			$(this).siblings("dt").removeClass("active");
		}
	);



});//]]>  
